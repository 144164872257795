import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Button, Modal, Row, Col } from "react-bootstrap";
import "../components/layout.scss";
import Layout from "../components/layout";
import { me } from "../api/users";
import { getUserQuiz, resetUserQuiz } from "../api/quiz";
import Auth from "../components/Auth";
import ProgramProgressSection from "../components/programProgressSection";

export const query = graphql`
  {
    progress: allContentfulProgramProgress {
      nodes {
        heading
        heading2
        description1 {
          description1
        }
        description2 {
          description2
        }
        speechGuidePdf {
          file {
            fileName
            url
          }
        }
      }
    }
    categories: allContentfulActionCategories {
      nodes {
        title
        questionIDs
        order
        durationInMins
        programDurationMessage {
          programDurationMessage
        }
        programFeaturesDescription {
          programFeaturesDescription
        }
      }
    }
    lessons: allContentfulLessons {
      nodes {
        lessonId
        lessonName
        lessonPlanPdfUrl
        lessonDescription {
          lessonDescription
        }
        tip1Title
        tip1Description {
          tip1Description
        }
        tip2Title {
          tip2Title
        }
        tip2Description {
          tip2Description
        }
        tip3Title
        tip3Description {
          tip3Description
        }
        videoName
        videoPlaceholderImage {
          fluid {
            src
          }
        }
        cloudinaryVideo {
          secure_url
          original_url
        }
        practiceChild
        correctPracticeLevels
        failedMessage {
          failedMessage
        }
      }
    }
    findingsDescription: contentfulFindings {
      descriptionRetakeAssessment {
        descriptionRetakeAssessment
      }
    }
  }
`;

function ProgramProgress({ data }) {
  const [userName, setUserName] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [preselectedCardIndex, setPreselectedCardIndex] = useState();
  const [selectedQuestion, setSelectedQuestion] = useState({ lessonId: null });
  const [userAnswers, setUserAnswersState] = useState({});
  const [replayQuiz, setReplayQuiz] = useState(false);

  const {
    categories: { nodes: categories },
    lessons: { nodes: lessons },
    findingsDescription: {
      descriptionRetakeAssessment: {
        descriptionRetakeAssessment
      }
    }
  } = data;

  let length = categories.length || 6;
  let activeStatusBars = length;
  let inactiveStatusBars = 0;
  let programStatusBar = [];
  let {
    progress: { nodes },
  } = data;
  let description1 = nodes[0].description1["description1"];
  let description2 = nodes[0].description2["description2"];
  let heading = nodes[0].heading;
  let heading2 = nodes[0].heading2;
  let speechFilePdf = nodes[0].speechGuidePdf
  let speechFilePdfUrl = speechFilePdf && speechFilePdf['file'] && speechFilePdf['file']['url'] || "";

  useEffect(async () => {
    let childName = localStorage.getItem("childName");
    if (childName) {
      setUserName(childName);
    } else {
      let data = await me();
      setUserName(data.childName);
    }
  }, []);

  const lessonCategories = categories
    .sort((a, b) => a.order - b.order)
    .map(category => ({
      title: category.title,
      order: category.order,
      lessons: category.questionIDs.map(
        questionId =>
          lessons.find(lesson => lesson.lessonId === questionId) || {}
      ),
    }));

  const renderSelectedCategoryName = () => {
    let categoryName = "";
    categories.forEach(item => {
      if (item.questionIDs.includes(selectedQuestion.lessonId)) {
        categoryName = item.title;
      }
    });
    return categoryName;
  };

  const sortedLessons = lessonCategories
    .map(item => item.lessons)
    .reduce((prev, next) => prev.concat(next));

  useEffect(async () => {
    try {
      setLoading(true);
      const { userAnswers, hasVisitedProgram } = await getUserQuiz();
      if (!hasVisitedProgram) {
        // replace /child-program with /toddler-talk-plan-tutorial url
        //window.location.href = "/child-program";
        window.location.href = '/toddler-talk-plan-tutorial'
      } else {
        let newUserAnswers = {};
        userAnswers.forEach(answer => {
          newUserAnswers[answer.questionId] = {
            answers: answer.answers,
            result: answer.result,
          };
        });
        setUserAnswersState(newUserAnswers);
        let item = sortedLessons.find(
          lesson =>
            !newUserAnswers[lesson.lessonId] ||
            (newUserAnswers[lesson.lessonId] &&
              !newUserAnswers[lesson.lessonId].result)
        );

        if (item && item["lessonId"]) {
          let lessonId = item["lessonId"];
          let index = categories.findIndex(data =>
            data["questionIDs"].includes(lessonId)
          );
          if (index > -1) {
            setPreselectedCardIndex(index);
            setSelectedQuestion(item);
          }
        } else {
          setPreselectedCardIndex(0);
          setSelectedQuestion(null);
        }
      }
      setLoading(false);
    } catch (err) {
      window.location.href = "/not-found";
      setLoading(false);
    }
  }, []);


  const restartQuizPopup = () => {
    setReplayQuiz(!replayQuiz);
  }

  const resetQuiz = async () => {
    let data = await resetUserQuiz();
    if (data === true) {
      setReplayQuiz(false);
      window.location.href = "/quiz";
    } else {
      setReplayQuiz(false);
    }
  }

  // const getBarColor = (questionIDs) => {
  //   let temp = 'progress-bar-capsule-active';
  //     questionIDs.forEach(item => {
  //       if(userAnswers[item] && userAnswers[item]['result']){
  //         //pass
  //       } else {
  //         temp = 'progress-bar-capsule';
  //       }
  //     })

  //   return temp;
  // }

  (function createBarCapsules() {
    categories.forEach(category => {
      let questionIDs = category.questionIDs;
      let update = false;
      questionIDs.forEach(item => {
        if (userAnswers[item] && userAnswers[item]["result"]) {
          //pass
        } else {
          update = true;
        }
      });
      if (update) {
        inactiveStatusBars = inactiveStatusBars + 1;
        activeStatusBars = activeStatusBars - 1;
      }
    });
    for (let i = 1; i <= activeStatusBars; i++) {
      programStatusBar.push(
        <div
          style={{ width: `calc(100vw / ${length})` }}
          className="progress-bar-capsule-active"
        ></div>
      );
    }
    for (let i = 1; i <= inactiveStatusBars; i++) {
      programStatusBar.push(
        <div
          style={{ width: `calc(100vw / ${length})` }}
          className="progress-bar-capsule"
        ></div>
      );
    }
  })();

  return (
    <Auth>
      <Layout>
        <div id="content" className="site-content">
          <div className="content-inner">
            <div className="">
              <div className="row content-row">
                <div id="primary" className="content-area col-12">
                  <main id="main" className="site-main">
                    <article
                      id="post-45"
                      className="post-45 page type-page status-publish hentry"
                    >
                      <div className="entry-content clearfix">
                        <div
                          data-elementor-type="wp-page"
                          data-elementor-id="45"
                          className="elementor elementor-45"
                          data-elementor-settings="[]"
                        >
                          <div className="elementor-section-wrap mt-5">
                            <section
                              className="elementor-section elementor-top-section elementor-element elementor-element-f6893e0 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                              data-id="f6893e0"
                              data-element_type="section"
                              data-settings='{"stretch_section":"section-stretched"}'
                            >
                              <div className="elementor-container elementor-column-gap-default ">
                                <div
                                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9f5f77a"
                                  data-id="9f5f77a"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-section elementor-inner-section elementor-element elementor-element-4522805 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                      data-id="4522805"
                                      data-element_type="section"
                                    >
                                      <div className="elementor-container elementor-column-gap-default ">
                                        <div
                                          className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-367b21f"
                                          data-id="367b21f"
                                          data-element_type="column"
                                        >
                                          <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                              className="elementor-element elementor-element-9265126 cms-element elementor-widget elementor-widget-cms_image_single"
                                              data-id="9265126"
                                              data-element_type="widget"
                                              data-widget_type="cms_image_single.default"
                                            >
                                              <div className="elementor-widget-container animation-top">
                                                <div className="cms-image-single cms-animation-left-to-right ">
                                                  <div className="cms-img-box">
                                                    <StaticImage
                                                      placeholder="none"
                                                      width="113"
                                                      height="117"
                                                      src="../images/image-home-2.png"
                                                      className="attachment-full size-full"
                                                      alt=""
                                                      loading="lazy"
                                                    />{" "}
                                                  </div>
                                                </div>{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-8869f2a"
                                          data-id="8869f2a"
                                          data-element_type="column"
                                        >
                                          <div className="elementor-widget-wrap elementor-element-populated">
                                            <div
                                              className="elementor-element elementor-element-8832270 elementor-widget elementor-widget-cms_heading"
                                              data-id="8832270"
                                              data-element_type="widget"
                                              data-widget_type="cms_heading.default"
                                            >
                                              <div className="elementor-widget-container payment-page-widget-container">
                                                <div className="cms-heading-wrapper cms-heading-layout1 wow pulse p-3">
                                                  <div className="custom-subheading">
                                                    Program Progress
                                                  </div>
                                                  <h3 className="custom-heading">
                                                    <span>
                                                      {userName}'s Toddler Talk
                                                      Plan
                                                    </span>
                                                  </h3>
                                                  <div class="custom-heading-description purple-text mb-3">
                                                    {description1}
                                                  </div>
                                                  <br />
                                                  <h4 className="custom-heading">
                                                    <span>{heading}</span>
                                                  </h4>
                                                  <div className="mt-5">
                                                    {/* <div className='progress-bar'>
                                                      {programStatusBar}
                                                      {
                                                        categories.map((catergory)=>{
                                                          return(
                                                            <div style={{width: `calc(100vw / ${categories.length})`}} className={getBarColor(catergory['questionIDs'])}></div>
                                                          )
                                                        })
                                                      } 
                                                    </div>
                                                    {activeStatusBars ? (
                                                      <p className="mb-0 mt-2 text-right">
                                                        {activeStatusBars}{" "}
                                                        categories completed
                                                      </p>
                                                    ) : null} */}
                                                    <div>
                                                      {selectedQuestion &&
                                                        selectedQuestion[
                                                        "lessonId"
                                                        ] ? (
                                                        <button
                                                          onClick={() => {
                                                            window.open(selectedQuestion.lessonPlanPdfUrl, '_blank')
                                                            // window.location.href = `/program?lessonId=${selectedQuestion["lessonId"]}`;
                                                          }}
                                                          className="btn lesson-plan-btn text-center"
                                                        >
                                                          <p className="lesson-plan-btn-text mb-0">
                                                            {selectedQuestion &&
                                                              selectedQuestion.lessonId
                                                              ? renderSelectedCategoryName()
                                                              : null}
                                                          </p>
                                                        </button>
                                                      ) : (
                                                        <button
                                                          onClick={() => {
                                                            window.location.href =
                                                              "/program-finished";
                                                          }}
                                                          className="btn lesson-plan-btn text-center"
                                                        >
                                                          <p className="lesson-plan-btn-text mb-0">
                                                            Program Finished
                                                          </p>
                                                        </button>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div
                                      className="elementor-section elementor-inner-section elementor-element elementor-element-b410ac4 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                      data-id="b410ac4"
                                      data-element_type="section"
                                    >
                                      <div className="elementor-container elementor-column-gap-default ">
                                        <div
                                          className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-0b4fe1f"
                                          data-id="0b4fe1f"
                                          data-element_type="column"
                                        ></div>
                                      </div>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>

                          <ProgramProgressSection
                            dataProp={data}
                            enableDirectQueOpening={true}
                            selectedQuestionProp={selectedQuestion}
                            userAnswersProp={userAnswers}
                            preselectedCardIndexProp={preselectedCardIndex}
                          />
                        </div>

                        <div
                          className=""
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <div
                            className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-8869f2a"
                            data-id="8869f2a"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated mt-5 mb-5">
                              <div
                                className="elementor-element elementor-element-8832270 elementor-widget elementor-widget-cms_heading"
                                data-id="8832270"
                                data-element_type="widget"
                                data-widget_type="cms_heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
                                    <div className="text-center">
                                      <h5 className="custom-heading mb-2">
                                        <span>Retake The Assessment</span>
                                      </h5>
                                      <div class="custom-heading-description purple-text mt-3 mb-3">
                                        {descriptionRetakeAssessment}
                                      </div>
                                      <button
                                        class="reset-quiz-btn"
                                       onClick={() => {
                                        window.location.href =
                                          "/toddler-talk-assessment-findings";
                                      }}
                                       >
                                        <span className="lesson-plan-btn-text">
                                          Show Findings
                                        </span>
                                      </button>
                                      <button
                                        class="reset-quiz-btn"
                                        onClick={restartQuizPopup}>
                                        <span className="lesson-plan-btn-text">
                                          Retake Assessment Now
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className=""
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <div
                            className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-8869f2a"
                            data-id="8869f2a"
                            data-element_type="column"
                          >
                            <div className="elementor-widget-wrap elementor-element-populated mt-5 mb-5">
                              <div
                                className="elementor-element elementor-element-8832270 elementor-widget elementor-widget-cms_heading"
                                data-id="8832270"
                                data-element_type="widget"
                                data-widget_type="cms_heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
                                    <div className="text-center">
                                      <h4 className="custom-heading mb-2">
                                        <span>{heading2}</span>
                                      </h4>
                                      <div class="custom-heading-description purple-text mb-3">
                                        {description2}
                                      </div>
                                      <button
                                        class="btn lesson-plan-btn mt-4"
                                        onClick={() => {
                                          window.open(`https:${speechFilePdfUrl}`, '_blank')
                                        }}
                                      >
                                        <span className="lesson-plan-btn-text">
                                          Download Speech Therapy Guide Now
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={replayQuiz ? true : false}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>Are you sure you want to retake the assessment ?</Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button onClick={() => { resetQuiz() }}>Yes</Button>
            <Button onClick={() => setReplayQuiz(false)}>No</Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </Auth>
  );
}

export default ProgramProgress;
